import * as React from "react";
import { Box } from "theme-ui";

export default function Footer() {
  return (
    <Box
      backgroundColor="gray10"
      sx={{ fontSize: [1, 1, 2], color: "gray5", textAlign: "center" }}
      p={4}
    >
      Copyright © 2021 GetStudio.Music by Otivia Ltd.
      <br />
      Company Number: 10057840
      <br />
      Intouch Accounting, Suite 1 Second Floor Everdene House, Deansleigh Road,
      <br />
      Bournemouth, United Kingdom, BH7 7DU
    </Box>
  );
}
