import * as React from "react";
import { Text, Box, Paragraph, Button } from "theme-ui";
import PageContainer from "../components/pageContainer";
import Heading from "./heading";

export default function CTA({ title = "Get In Touch Now" }) {
  return (
    <Box px={4} py={5} sx={{ color: "gray1" }} bg="black">
      <PageContainer
        sx={{
          display: "flex",
          flexDirection: ["column", "column", "row"],
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Heading level={3}>{title}</Heading>
          <Paragraph>
            Send us an email to{" "}
            <Text sx={{ fontWeight: "bold", color: "primary4" }}>
              record@getmusic.studio
            </Text>{" "}
            and we will take you from there
          </Paragraph>
        </Box>
        <Box>
          <Button
            onClick={() => (window.location = "mailto:record@getmusic.studio")}
            sx={{
              cursor: "pointer",
              px: [3, 3, 4],
              py: [2, 2, 3],
              mt: [2, 2, 0],
              width: ["100%", "100%", "auto"],
              fontSize: [2, 2, 3],
              "&:hover": { backgroundColor: "primary5" },
            }}
            bg="primary4"
          >
            Contact Us
          </Button>
        </Box>
      </PageContainer>
    </Box>
  );
}
