import * as React from "react";
import { Text } from "theme-ui";

const styleMap = {
  1: {
    fontFamily: "heading",
    fontSize: [4, 5, 6],
    fontWeight: "bold",
    filter: "drop-shadow(0 0 4px black)",
  },
  2: {
    fontFamily: "heading",
    fontSize: [3, 4, 6],
    fontWeight: "bold",
  },
  3: {
    fontFamily: "heading",
    fontSize: [3, 4, 5],
    fontWeight: "bold",
    filter: "drop-shadow(0 0 4px black)",
    color: "gray3",
  },
  4: {
    fontFamily: "heading",
    fontSize: [2, 3, 4],
    fontWeight: "bold",
    filter: "drop-shadow(0 0 4px black)",
    color: "gray3",
  },
};

export default function Heading(props) {
  const { level = 1, ...rest } = props;
  return (
    <Text
      as={`h${level}`}
      {...rest}
      sx={{
        ...styleMap[level],
        ...rest.sx,
      }}
    ></Text>
  );
}
