import { Link } from "gatsby";
import * as React from "react";
import {
  Text,
  Themed,
  Button,
  Box,
  Container,
  Image,
  Grid,
  Flex,
  useThemeUI,
} from "theme-ui";
import section1 from "../images/studios/DSC_6452.jpg";
import section2 from "../images/studios/DSC_6484.jpg";
import anej from "../images/people/anej.jpg";
import david from "../images/people/david.jpg";
import pero from "../images/people/pero.jpg";
import teo from "../images/people/teo.jpg";
import logo from "../images/logo-experiment.svg";
import slovenia from "../images/slovenia.svg";
import "../fonts/icomoon.css";
import Footer from "../components/footer";
import CTA from "../components/cta";
import Heading from "../components/heading";
import example1 from "../audio/unmastered_example1.mp3";
import example2 from "../audio/unmastered_example2.mp3";
import PageContainer from "../components/pageContainer";

const absoluteFill = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

const choiceButton = {
  border: "2px solid rgba(255,255,255,0.7)",
  color: "rgba(255,255,255,0.8)",
  background: "rgba(0,0,0,0.8)",
  borderRadius: 16,
  textAlign: "center",
  cursor: "pointer",
  fontSize: 3,
  boxShadow: "0px 1px 0px 1px rgba(255,255,255,0.3)",
  "&:hover": {
    border: "2px solid rgba(255,255,255,1)",
    borderColor: "primary5",
    background: "black",
    color: "primary5",
    boxShadow: "0 2px 4px 4px rgba(255,255,255,0.3)",
  },
};

const choiceButtonActive = {
  borderColor: "primary3",
  color: "primary3",
  // backgroundColor: "primary",
  // color: "black",
};

function SubPageButton({ to, currentPage, text }) {
  return (
    <Link style={{ textDecoration: "none" }} to={to}>
      <Box
        p={2}
        px={4}
        sx={{
          ...choiceButton,
          ...(currentPage === to ? choiceButtonActive : {}),
        }}
        m={2}
      >
        <Text sx={{ fontWeight: "bold" }}>{text}</Text>
      </Box>
    </Link>
  );
}

// markup
const Landing = ({ title, subtitle, backgroundImage, currentPage }) => {
  return (
    <>
      <Box
        sx={{
          height: "90vh",
          position: "relative",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Box
          sx={{
            ...absoluteFill,
            background: "rgba(0,0,0,0.4)",
          }}
        ></Box>
        <Smudge />

        <Box
          sx={{
            ...absoluteFill,
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Heading
            level={1}
            sx={{ maxWidth: ["100%", "100%", "500px"], textAlign: "center" }}
          >
            {title}
          </Heading>
          <Box my={4} mx={4} sx={{ textAlign: "center" }}>
            <Heading level={3}>{subtitle}</Heading>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: ["column", "column", "row"] }}
          >
            <SubPageButton
              text="For Indies"
              to="/for-indies"
              currentPage={currentPage}
            />
            <SubPageButton
              text="For Game Studios"
              to="/"
              currentPage={currentPage}
            />
            <SubPageButton
              text="For Composers"
              to="/for-composers"
              currentPage={currentPage}
            />
          </Box>
        </Box>
      </Box>
      <CTA />
      <Section
        side="left"
        image={section1}
        caption="Studio 1"
        title="What can we do?"
        sx={{ position: "relative" }}
      >
        <Smudge
          sx={{ left: 0, right: 0, top: 0, bottom: "auto" }}
          path={topSmudgePath}
        />
        <ListItem icon="icon-music">Adaptive Music</ListItem>
        <ListItem icon="icon-volume-high">Sound Effects</ListItem>
        <ListItem icon="icon-headphones">Mastering, Mixing, Recording</ListItem>
        <ListItem icon="icon-fmod" iconSize={5}>
          We can take over FMOD fully or in part
        </ListItem>
      </Section>
      <Section
        smudgeColor="black"
        side="right"
        image={section2}
        title="Why us?"
        caption="Studio 1"
      >
        <ListItem icon="icon-users">Rich Network of Musicians</ListItem>
        <ListItem icon="icon-volume-high">
          10+ years of production experience
        </ListItem>
        <ListItem icon="icon-headphones">
          Flexible &amp; commited to quality
        </ListItem>
      </Section>
      {/*
      <Box bg="gray9">
        <PageContainer
          py={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Heading level={2}>Listen to Our Work</Heading>
          <Box
            my={4}
            sx={{
              maxWidth: ["100%", "100%", "1024px"],
              display: "flex",
              flexDirection: ["column", "column", "row"],
            }}
          >
            <PlayCard
              title="Fantasy Concept"
              text="Made for open worlds in mind, with loopable segments and adjustable intensity."
              src={example1}
              bg="gray3"
            />
            <PlayCard
              title="Space Concept"
              text="Loopable, progressive example we made during our exploration days."
              src={example2}
              bg="gray2"
            />
            <PlayCard
              title="StressOut VR"
              text="Funky adaptive music that changes as the player performs different actions in game."
              src={example2}
              bg="gray1"
            />
          </Box>
        </PageContainer>
      </Box>
      */}
      <CTA title="Need a quote?" />
      <SectionProfiles />
      <Footer />
    </>
  );
};

function PlayCard({ title, text, src, bg }) {
  const theme = useThemeUI();
  const c1 = theme.theme.colors.gray11;
  const c2 = theme.theme.colors.gray9;
  return (
    <Box
      m={[3, 3, 4]}
      sx={{
        flexGrow: 1,
        flexBasis: 1,
        borderRadius: 32,
        overflow: "hidden",
        flexDirection: "column",
        display: "flex",
        color: "gray4",
        minHeight: "220px",
        background: `linear-gradient(304deg, ${c1} 0%, ${c2} 100%)`,
        filter: "drop-shadow(0 0 4px black)",
      }}
    >
      <Heading sx={{ color: "gray1", filter: "" }} level={4} mt={3} mx={4}>
        {title}
      </Heading>
      <Text mb={4} mt={3} mx={4} sx={{ flexGrow: 1 }}>
        {text}
      </Text>
      <audio
        controls
        style={{
          width: "100%",
          filter: "drop-shadow(0 2px 4px rgba(0,0,0,0.3))",
        }}
      >
        <source src={src} type="audio/mpeg" />
      </audio>
    </Box>
  );
}

function SectionProfiles() {
  return (
    <Box
      backgroundColor="gray11"
      py={[2, 2, 4]}
      sx={{
        backgroundImage: `url(${slovenia})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Text
        as="h3"
        my={3}
        sx={{
          fontFamily: "heading",
          fontSize: [3, 4, 6],
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Who are we?
      </Text>
      <Text
        px={3}
        sx={{
          fontSize: [2, 2, 3],
          textAlign: "center",
          display: "block",
          maxWidth: "550px",
          margin: "8px auto",
          color: "gray3",
        }}
      >
        A core group of 4, inspired by gaming and interactive media. We breathe
        music and audio.
      </Text>
      <Flex
        py={4}
        pb={5}
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Profile role="Producer" image={anej} alt="Anej Strafela" />
        <Profile role="FMOD Engineer" image={david} alt="David Krajnc" />
        <Profile role="Audio Engineer" image={pero} alt="Peter" />
        <Profile role="Client Director" image={teo} alt="Teodor Polic" />
      </Flex>
      <Text
        as="h4"
        my={3}
        sx={{
          fontFamily: "heading",
          fontSize: [2, 3, 5],
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        20+ Musicians
      </Text>
      <Text
        px={3}
        sx={{
          fontSize: [2, 2, 3],
          textAlign: "center",
          display: "block",
          maxWidth: "550px",
          margin: "8px auto",
          color: "gray2",
        }}
      >
        Supported by many amazing musicians that make our work possible. From
        pianists to singers and even entire choirs.
      </Text>
      <Flex
        py={4}
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <MiniProfile></MiniProfile>
        <MiniProfile></MiniProfile>
        <MiniProfile></MiniProfile>
        <MiniProfile></MiniProfile>
        <MiniProfile></MiniProfile>
        <MiniProfile></MiniProfile>
        <MiniProfile></MiniProfile>
        <MiniProfile></MiniProfile>
      </Flex>
    </Box>
  );
}

function MiniProfile() {
  return (
    <Flex
      m={2}
      sx={{
        width: 64,
        height: 64,
        borderRadius: 32,
        alignItems: "center",
        justifyContent: "center",
        fontSize: 4,
        color: "gray2",
      }}
      backgroundColor="gray8"
      className="icon-user"
    />
  );
}

function Profile({ image, alt, role }) {
  return (
    <Box>
      <Box
        aria-label={alt}
        sx={{
          margin: ["4px auto", "4px auto", 4],
          borderRadius: ["24px", "32px", "64px"],
          width: ["48px", "64px", "128px"],
          height: ["48px", "64px", "128px"],
          overflow: "hidden",
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></Box>
      <Text
        sx={{
          color: "gray3",
          fontFamily: "heading",
          textAlign: "center",
          display: "block",
          fontSize: [1, 2, 3],
        }}
      >
        {alt}
      </Text>
      <Text
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          display: "block",
          fontSize: [1, 2, 2],
        }}
      >
        {role}
      </Text>
    </Box>
  );
}

function Section({ smudgeColor, side, children, image, caption, title, sx }) {
  const isLeft = side === "left";
  const theme = useThemeUI();
  const c1 = theme.theme.colors.gray11;
  const c2 = theme.theme.colors.gray10;
  return (
    <Box
      backgroundColor={isLeft ? "gray11" : "gray10"}
      sx={{ position: "relative", ...sx }}
      pb={5}
    >
      <Smudge fill={smudgeColor ? smudgeColor : isLeft ? c2 : c1} />
      <Flex
        sx={{
          flexDirection: [
            "column-reverse",
            "column-reverse",
            isLeft ? "row" : "row-reverse",
          ],
        }}
      >
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: "center",
            maxWidth: ["100%", "100%", "50%"],
            maxHeight: ["300px", "300px", "100%"],
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Image
            sx={{
              width: ["100%", "100%", "70%"],
            }}
            src={image}
            alt={caption}
          />
        </Flex>
        <List title={title}>{children}</List>
      </Flex>
    </Box>
  );
}

const listItem = {
  fontSize: [3, 3, 4],
  my: [1, 1, 2],
};

const listItemHolder = {
  flexDirection: "row",
  alignItems: "center",
  color: "gray1",
  ":nth-of-type(2n)": {
    color: "gray4",
  },
};

function List({ children, title }) {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      p={[3, 3, 4]}
      py={[4, 4, 6]}
    >
      <Box>
        <Heading level={2} sx={{ marginTop: 0 }}>
          {title}
        </Heading>
        <Box
          sx={{
            background: "primary1",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}

function ListItem({ icon, children, iconSize }) {
  return (
    <Flex sx={listItemHolder}>
      {icon && (
        <Box
          sx={{ fontSize: iconSize || 3, width: 32 }}
          mr={3}
          className={icon}
        ></Box>
      )}
      <Text sx={listItem}>{children}</Text>
    </Flex>
  );
}

const defaultSmudgePath =
  "M-7.89,84.38 C164.21,0.50 343.68,201.80 500.00,49.98 L500.00,150.00 L0.00,150.00 Z";

const topSmudgePath =
  "M0.00,49.98 C136.57,-95.22 344.80,155.42 500.00,49.98 L500.00,0.00 L0.00,0.00 Z";

function Smudge({ path = defaultSmudgePath, sx, fill = "black" }) {
  return (
    <Box
      sx={{
        height: [80, 80, 150],
        width: "100%",
        overflow: "hidden",
        position: "absolute",
        bottom: 0,
        ...sx,
      }}
    >
      <svg
        viewBox="0 0 500 150"
        preserveAspectRatio="none"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <path d={path} style={{ stroke: "none", fill }}></path>
      </svg>
    </Box>
  );
}

export default Landing;
